import { ErrorHead } from '@/components/common/commonHead'
import Footer from '@/components/guest/invitations/footer'
import { errorPageStyles } from '@/styles/guest/errorPageStyle'
import { mqSp } from '@/styles/guest/styleConstants'
import { css } from '@emotion/react'

export default function GuestForbiddenError() {
  const handleBackButtonClick = () => {
    window.location.reload()
  }
  return (
    <div css={errorPageStyles.root}>
      <ErrorHead />
      <div css={errorPageStyles.main}>
        <div css={[errorPageStyles.container, styles.container]}>
          <h1 css={errorPageStyles.title}>エラーが発生しました</h1>
          <p css={errorPageStyles.text}>
            入力禁止文字列が入力されたか、
            <br />
            禁止箇所にアクセスしている
            <br />
            可能性があります。
            <br />
            恐れ入りますが、
            <br />
            再度操作をやり直してください。
          </p>
          <button
            css={[errorPageStyles.button, styles.button]}
            type="button"
            onClick={handleBackButtonClick}
          >
            前の画面に戻る
          </button>
        </div>
      </div>
      <div css={errorPageStyles.footer}>
        <Footer pattern="white" />
      </div>
    </div>
  )
}

const styles = {
  container: css`
    ${mqSp()} {
      padding: 44px 37px;
    }
  `,
  button: css`
    width: 247px;
    height: 44px;
    font-size: 12px;
    :hover {
      background: linear-gradient(0deg, rgba(234, 96, 119, 0.1), rgba(234, 96, 119, 0.1)), #ffffff;
    }
  `,
}
