import { PageNotFoundErrorHead } from '@/components/common/commonHead'
import { LoggingErrorPageView } from '@/components/common/googleTagManager'
import CheckOnlyAuthentication from '@/components/cs/auth/checkOnlyAuthentication'
import { RoundMBorderButton } from '@/components/cs/button'
import { ErrorLayout, styles } from '@/components/cs/errorLayout'

export default function PageError() {
  return (
    <ErrorLayout>
      <PageNotFoundErrorHead />
      <LoggingErrorPageView original_screen_name={'cs_error_404'} />
      <h1 css={styles.head}>
        お探しのページが
        <br />
        見つかりませんでした
      </h1>

      <p css={styles.text}>
        URLが間違っているか、ページが移動、
        <br />
        もしくは削除されている可能性があります。
        <CheckOnlyAuthentication>
          <br />
          お手数ですが、パーティトップ画面から
          <br />
          お探しください。
        </CheckOnlyAuthentication>
      </p>
      <CheckOnlyAuthentication>
        {/* ErrorContextなどの状態破棄のためwindow.location.assignを利用 */}
        <RoundMBorderButton
          label="パーティトップへ"
          css={styles.button}
          onClick={() => window.location.assign('/cs')}
        />
      </CheckOnlyAuthentication>
    </ErrorLayout>
  )
}
