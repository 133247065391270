import { randomBytes } from 'crypto'

import ClarityScript from '@/components/common/clarityScriptProvider'
import GoogleTagManager from '@/components/common/googleTagManager'
import { KarteMeasurementTag } from '@/components/common/karte/karteTagProvider'
import { Config, ConfigContextProvider, useConfigFactory } from '@/context/config'
import { ErrorContextProvider, useError, useErrorFactory } from '@/context/error'
import { LoadingContextProvider, useLoadingFactory } from '@/context/loading'
import DefaultError from '@/pages/_error'
import { globalStyles } from '@/styles/globalStyle'
import { FEATURE_TOGGLE_TYPE, isDevelopment } from '@/utils/common/featureToggle'
import { NextComponentType, NextPageContext } from 'next'
import React from 'react'
import { SWRConfig } from 'swr'

type AppProps = {
  config: Config
  pageProps: any
  Component: NextComponentType<NextPageContext, any, any> & {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
    getLayout: ({ page, ...props }) => React.Component
    layoutProps: any
  }
}

const DefaultLayout = ({ Component, pageProps }: AppProps) => {
  const { statusCode, errorCode } = useError()
  if (statusCode || errorCode) {
    return <DefaultError statusCode={statusCode} errorCode={errorCode} />
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  const Layout = Component.getLayout ? Component.getLayout : ({ page }) => page
  const layoutProps = Component.layoutProps ? Component.layoutProps : null
  return Layout({
    page: <Component {...pageProps} />,
    ...layoutProps,
  })
}

export default function App(props: AppProps) {
  const { config } = props

  const loadingCtx = useLoadingFactory()
  const errorCtx = useErrorFactory()
  const configCtx = useConfigFactory(config)

  // 各スクリプトの埋め込み用にCSPのnonce値をconfig経由で取得する
  return (
    <>
      <GoogleTagManager googleTagManagerId={configCtx.config.gtm} nonce={config.cspNonce} />
      {globalStyles}
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          dedupingInterval: 3000,
          focusThrottleInterval: 0,
        }}
      >
        <ConfigContextProvider {...configCtx}>
          <KarteMeasurementTag nonce={config.cspNonce} />
          {isDevelopment(configCtx.config.featureToggle) && (
            <ClarityScript clarityId={configCtx.config.clarityId} nonce={config.cspNonce} />
          )}
          <ErrorContextProvider {...errorCtx}>
            <LoadingContextProvider {...loadingCtx}>
              <DefaultLayout {...props} />
            </LoadingContextProvider>
          </ErrorContextProvider>
        </ConfigContextProvider>
      </SWRConfig>
    </>
  )
}

type GetInitialPropsProps = {
  ctx: NextPageContext
}
App.getInitialProps = async ({ ctx }: GetInitialPropsProps) => {
  const env = process.env.APP_ENV
  const nonce = createNonceAndSetCSP(ctx)

  const createConfig = (): Config => {
    switch (env) {
      case 'dev':
        return {
          featureToggle: FEATURE_TOGGLE_TYPE.dev,
          datadogEnv: process.env.DD_ENV,
          gtm: 'GTM-WM2DRDJ',
          clarityId: 'lie9v13jq0',
          clUserManual:
            'https://wwwtst.cszebra.zexy.net/z/contents/manual/pdf/xyinvitaiton_startedguide.pdf',
          clZebraUrl: 'https://wwwtst.cszebra.zexy.net/id/login/',
          // dev2 と ローカルで適用される設定となっており、 dev2 を優先するためローカルからも dev2 を参照するようになっている
          // 必要に応じて設定を変えるもしくは設定を増やす方針を対応する
          serviceUrl: 'https://dev2.xyinvitaiton-dev.com',
          coupleLpUrl: 'https://preview.studio.site/live/xmaZYlNJqR',
          csInvitationGuidanceArticleUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/knowledge_text',
          csGoshugiExperiencesArticleUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/usercase_realvoice3',
          csGoshugiResearchArticleUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/knowledge_guest2',
          csImageShareManualUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/guide/createinvitation_photosharing',
          csInstagramCampaignUrl: 'https://preview.studio.site/live/xmaZYlNJqR/instagram_campaign',
          csGuestListGuideUrl: 'https://preview.studio.site/live/xmaZYlNJqR/guide/checkguest',
          guestGoshugiQuestionsUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/knowledge_guest1',
          coupleGuestListUpServiceTermUrl:
            'https://preview.p.recruit.co.jp/terms/xys-t-1012/index.html',
          cspNonce: nonce,
        }
      case 'demo':
        return {
          featureToggle: FEATURE_TOGGLE_TYPE.demo,
          datadogEnv: process.env.DD_ENV,
          gtm: 'GTM-WM2DRDJ',
          clarityId: 'invalid-id', // TODO: XYSAAS-8916 本番環境で利用 Ready になった際には発行されたIDを設定する
          clUserManual:
            'https://wwwtst.cszebra.zexy.net/z/contents/manual/pdf/xyinvitaiton_startedguide.pdf',
          clZebraUrl: 'https://wwwtst.cszebra.zexy.net/id/login/',
          serviceUrl: 'https://dev3.xyinvitaiton-dev.com',
          coupleLpUrl: 'https://preview.studio.site/live/xmaZYlNJqR',
          csInvitationGuidanceArticleUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/knowledge_text',
          csGoshugiExperiencesArticleUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/usercase_realvoice3',
          csGoshugiResearchArticleUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/knowledge_guest2',
          csImageShareManualUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/guide/createinvitation_photosharing',
          csInstagramCampaignUrl: 'https://preview.studio.site/live/xmaZYlNJqR/instagram_campaign',
          csGuestListGuideUrl: 'https://preview.studio.site/live/xmaZYlNJqR/guide/checkguest',
          guestGoshugiQuestionsUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/knowledge_guest1',
          coupleGuestListUpServiceTermUrl:
            'https://preview.p.recruit.co.jp/terms/xys-t-1012/index.html',
          cspNonce: nonce,
        }
      case 'stg':
        return {
          featureToggle: FEATURE_TOGGLE_TYPE.stg,
          datadogEnv: process.env.DD_ENV,
          gtm: 'GTM-WM2DRDJ',
          clarityId: 'invalid-id', // TODO: XYSAAS-8916 本番環境で利用 Ready になった際には発行されたIDを設定する
          clUserManual:
            'https://wwwtst.cszebra.zexy.net/z/contents/manual/pdf/xyinvitaiton_startedguide.pdf',
          clZebraUrl: 'https://wwwtst.cszebra.zexy.net/id/login/',
          serviceUrl: 'https://dev1.xyinvitaiton-dev.com',
          coupleLpUrl: 'https://preview.studio.site/live/xmaZYlNJqR',
          csInvitationGuidanceArticleUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/knowledge_text',
          csGoshugiExperiencesArticleUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/usercase_realvoice3',
          csGoshugiResearchArticleUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/knowledge_guest2',
          csImageShareManualUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/guide/createinvitation_photosharing',
          csInstagramCampaignUrl: 'https://preview.studio.site/live/xmaZYlNJqR/instagram_campaign',
          csGuestListGuideUrl: 'https://preview.studio.site/live/xmaZYlNJqR/guide/checkguest',
          guestGoshugiQuestionsUrl:
            'https://preview.studio.site/live/xmaZYlNJqR/article/knowledge_guest1',
          coupleGuestListUpServiceTermUrl:
            'https://preview.p.recruit.co.jp/terms/xys-t-1012/index.html',
          cspNonce: nonce,
        }
      default:
        return {
          featureToggle: FEATURE_TOGGLE_TYPE.prd,
          datadogEnv: process.env.DD_ENV,
          gtm: 'GTM-WM2DRDJ',
          clarityId: 'invalid-id', // TODO: XYSAAS-8916 本番環境で利用 Ready になった際には発行されたIDを設定する
          clUserManual:
            'https://cszebra.zexy.net/z/contents/manual/pdf/xyinvitaiton_startedguide.pdf',
          clZebraUrl: 'https://cszebra.zexy.net/id/login/',
          serviceUrl: 'https://online.zexy.net',
          coupleLpUrl: 'https://introduction.online.zexy.net',
          csInvitationGuidanceArticleUrl:
            'https://introduction.online.zexy.net/article/knowledge_text',
          csGoshugiExperiencesArticleUrl:
            'https://introduction.online.zexy.net/article/usercase_realvoice3',
          csGoshugiResearchArticleUrl:
            'https://introduction.online.zexy.net/article/knowledge_guest2',
          csImageShareManualUrl:
            'https://introduction.online.zexy.net/guide/createinvitation_photosharing',
          csInstagramCampaignUrl: 'https://introduction.online.zexy.net/instagram_campaign',
          csGuestListGuideUrl: 'https://introduction.online.zexy.net/guide/checkguest',
          guestGoshugiQuestionsUrl: 'https://introduction.online.zexy.net/article/knowledge_guest1',
          coupleGuestListUpServiceTermUrl:
            'https://cdn.p.recruit.co.jp/terms/xys-t-1012/index.html',
          cspNonce: nonce,
        }
    }
  }

  return {
    config: createConfig(),
  }
}

/*
 * カスタムAppでnonceを生成してCSPヘッダーに設定する
 * ここで設定したnonce値をカスタムDocumentでのスクリプトの埋め込みに利用している
 *
 * CSP自体の仕様については以下を参照
 * - https://web.dev/articles/strict-csp?hl=ja
 *
 * Next.jsのCSP対応については以下を参照
 * - https://nextjs.org/docs/13/pages/building-your-application/configuring/content-security-policy
 *
 * 関連する実装箇所については以下を参照
 * - pages/_document.tsx
 */
const createNonceAndSetCSP = (ctx: NextPageContext): string | undefined => {
  if (!ctx.req || !ctx.req.url || !ctx.res) {
    return undefined
  }

  // /guest/ 以下のパスを対象にする
  // 検証中はdev2環境でのみ有効にしたいので環境変数のAPP_ENVを参照している
  const isAppEnvDev = process.env.APP_ENV === 'dev'
  const isPathUnderGuest = /^\/guest\//.test(ctx.req.url)
  if (!isAppEnvDev || !isPathUnderGuest) {
    return undefined
  }

  const nonce = randomBytes(128).toString('base64')
  let cspScriptSources = `'nonce-${nonce}' 'strict-dynamic' https: 'unsafe-inline'`

  // 開発中のホットリロード対応のためにunsafe-evalを許可する
  // ローカルでのデバッグ実行時のみ対象にしたいので環境変数のNODE_ENVを参照している
  const allowsUnsafeEval = process.env.NODE_ENV === 'development'
  if (allowsUnsafeEval) {
    cspScriptSources += " 'unsafe-eval'"
  }

  const datadogEnv = process.env.DD_ENV
  const cspHeaderValue = `
    script-src ${cspScriptSources};
    object-src 'none';
    base-uri 'none';
    report-uri ${datadogCSPReportURL(datadogEnv)}
  `
    .replace(/\s{2,}/g, ' ')
    .trim()
  ctx.res.setHeader('x-nonce', nonce)
  ctx.res.setHeader('Content-Security-Policy-Report-Only', cspHeaderValue)

  return nonce
}

// DatadogのCSPレポート用URL
// see also: https://docs.datadoghq.com/ja/integrations/content_security_policy_logs/
const datadogCSPReportURL = (env: string | undefined) => {
  const params = new URLSearchParams({
    'dd-api-key': 'pub7e2c08ef7c68dc143c6ed6d33a742139',
    'dd-evp-origin': 'content-security-policy',
    ddsource: 'csp-report',
    ddtags: encodeURI(`service:csp-report,env:${env}`),
  })
  return `https://browser-intake-datadoghq.com/api/v2/logs?${params}`
}
