/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { CoupleInvitationResponsesRequest } from '../model'
// @ts-ignore
import { CoupleInvitationResponsesResponse } from '../model'
// @ts-ignore
import { ErrorResponse } from '../model'
// @ts-ignore
import { GetGuestsForCoupleCSVResponse } from '../model'
// @ts-ignore
import { GetGuestsForCoupleWPSCSVResponse } from '../model'
/**
 * CoupleInvitationResponseControllerApi - axios parameter creator
 * @export
 */
export const CoupleInvitationResponseControllerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CoupleInvitationResponsesRequest} coupleInvitationResponsesRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getInvitationResponses: async (
      coupleInvitationResponsesRequest: CoupleInvitationResponsesRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'coupleInvitationResponsesRequest' is not null or undefined
      assertParamExists(
        'getInvitationResponses',
        'coupleInvitationResponsesRequest',
        coupleInvitationResponsesRequest,
      )
      const localVarPath = `/cs/api/getInvitationResponses`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        coupleInvitationResponsesRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getInvitationResponsesForCSV: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/cs/api/getInvitationResponsesForCSV`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getInvitationResponsesForWPSCSV: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/cs/api/getInvitationResponsesForWPSCSV`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CoupleInvitationResponseControllerApi - functional programming interface
 * @export
 */
export const CoupleInvitationResponseControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CoupleInvitationResponseControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {CoupleInvitationResponsesRequest} coupleInvitationResponsesRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async getInvitationResponses(
      coupleInvitationResponsesRequest: CoupleInvitationResponsesRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoupleInvitationResponsesResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationResponses(
        coupleInvitationResponsesRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async getInvitationResponsesForCSV(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestsForCoupleCSVResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationResponsesForCSV(
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async getInvitationResponsesForWPSCSV(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestsForCoupleWPSCSVResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationResponsesForWPSCSV(
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CoupleInvitationResponseControllerApi - factory interface
 * @export
 */
export const CoupleInvitationResponseControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CoupleInvitationResponseControllerApiFp(configuration)
  return {
    /**
     *
     * @param {CoupleInvitationResponsesRequest} coupleInvitationResponsesRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getInvitationResponses(
      coupleInvitationResponsesRequest: CoupleInvitationResponsesRequest,
      options?: any,
    ): AxiosPromise<CoupleInvitationResponsesResponse> {
      return localVarFp
        .getInvitationResponses(coupleInvitationResponsesRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getInvitationResponsesForCSV(options?: any): AxiosPromise<GetGuestsForCoupleCSVResponse> {
      return localVarFp
        .getInvitationResponsesForCSV(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getInvitationResponsesForWPSCSV(options?: any): AxiosPromise<GetGuestsForCoupleWPSCSVResponse> {
      return localVarFp
        .getInvitationResponsesForWPSCSV(options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * CoupleInvitationResponseControllerApi - object-oriented interface
 * @export
 * @class CoupleInvitationResponseControllerApi
 * @extends {BaseAPI}
 */
export class CoupleInvitationResponseControllerApi extends BaseAPI {
  /**
   *
   * @param {CoupleInvitationResponsesRequest} coupleInvitationResponsesRequest
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof CoupleInvitationResponseControllerApi
   */
  public getInvitationResponses(
    coupleInvitationResponsesRequest: CoupleInvitationResponsesRequest,
    options?: AxiosRequestConfig,
  ) {
    return CoupleInvitationResponseControllerApiFp(this.configuration)
      .getInvitationResponses(coupleInvitationResponsesRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof CoupleInvitationResponseControllerApi
   */
  public getInvitationResponsesForCSV(options?: AxiosRequestConfig) {
    return CoupleInvitationResponseControllerApiFp(this.configuration)
      .getInvitationResponsesForCSV(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof CoupleInvitationResponseControllerApi
   */
  public getInvitationResponsesForWPSCSV(options?: AxiosRequestConfig) {
    return CoupleInvitationResponseControllerApiFp(this.configuration)
      .getInvitationResponsesForWPSCSV(options)
      .then((request) => request(this.axios, this.basePath))
  }
}
