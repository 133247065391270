/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { ErrorResponse } from '../model'
// @ts-ignore
import { GetBucketGuestExistenceRequest } from '../model'
// @ts-ignore
import { GetBucketGuestExistenceResponse } from '../model'
// @ts-ignore
import { GetBucketGuestsForMyHistoryRequest } from '../model'
// @ts-ignore
import { GetBucketGuestsForMyHistoryResponse } from '../model'
// @ts-ignore
import { GetGuestCountForOnboardingResponse } from '../model'
// @ts-ignore
import { GetGuestsForCsvRequest } from '../model'
// @ts-ignore
import { GetGuestsForCsvResponse } from '../model'
// @ts-ignore
import { GetInfoForGuestBucketSelectionResponse } from '../model'
// @ts-ignore
import { RegisterMyHistoryCompleteRequest } from '../model'
// @ts-ignore
import { RegisterMyHistoryCompleteResponse } from '../model'
// @ts-ignore
import { RegisterOnboardingCompleteRequest } from '../model'
// @ts-ignore
import { RegisterOnboardingCompleteResponse } from '../model'
/**
 * GuestBucketControllerApi - axios parameter creator
 * @export
 */
export const GuestBucketControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {GetBucketGuestExistenceRequest} getBucketGuestExistenceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBucketGuestExistence: async (
      getBucketGuestExistenceRequest: GetBucketGuestExistenceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getBucketGuestExistenceRequest' is not null or undefined
      assertParamExists(
        'getBucketGuestExistence',
        'getBucketGuestExistenceRequest',
        getBucketGuestExistenceRequest,
      )
      const localVarPath = `/cs_guest_list_up/api/getBucketGuestExistence`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getBucketGuestExistenceRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetBucketGuestsForMyHistoryRequest} getBucketGuestsForMyHistoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBucketGuestsForMyHistory: async (
      getBucketGuestsForMyHistoryRequest: GetBucketGuestsForMyHistoryRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getBucketGuestsForMyHistoryRequest' is not null or undefined
      assertParamExists(
        'getBucketGuestsForMyHistory',
        'getBucketGuestsForMyHistoryRequest',
        getBucketGuestsForMyHistoryRequest,
      )
      const localVarPath = `/cs_guest_list_up/api/getBucketGuestsForMyHistory`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getBucketGuestsForMyHistoryRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGuestCountForOnboarding: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cs_guest_list_up/api/getGuestCountForOnboarding`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetGuestsForCsvRequest} getGuestsForCsvRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGuestsForCsv: async (
      getGuestsForCsvRequest: GetGuestsForCsvRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getGuestsForCsvRequest' is not null or undefined
      assertParamExists('getGuestsForCsv', 'getGuestsForCsvRequest', getGuestsForCsvRequest)
      const localVarPath = `/cs_guest_list_up/api/getGuestsForCsv`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getGuestsForCsvRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfoForGuestBucketSelection: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/cs_guest_list_up/api/getInfoForGuestBucketSelection`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {RegisterMyHistoryCompleteRequest} registerMyHistoryCompleteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerMyHistoryComplete: async (
      registerMyHistoryCompleteRequest: RegisterMyHistoryCompleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'registerMyHistoryCompleteRequest' is not null or undefined
      assertParamExists(
        'registerMyHistoryComplete',
        'registerMyHistoryCompleteRequest',
        registerMyHistoryCompleteRequest,
      )
      const localVarPath = `/cs_guest_list_up/api/registerMyHistoryComplete`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        registerMyHistoryCompleteRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {RegisterOnboardingCompleteRequest} registerOnboardingCompleteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerOnboardingComplete: async (
      registerOnboardingCompleteRequest: RegisterOnboardingCompleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'registerOnboardingCompleteRequest' is not null or undefined
      assertParamExists(
        'registerOnboardingComplete',
        'registerOnboardingCompleteRequest',
        registerOnboardingCompleteRequest,
      )
      const localVarPath = `/cs_guest_list_up/api/registerOnboardingComplete`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        registerOnboardingCompleteRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * GuestBucketControllerApi - functional programming interface
 * @export
 */
export const GuestBucketControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GuestBucketControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {GetBucketGuestExistenceRequest} getBucketGuestExistenceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBucketGuestExistence(
      getBucketGuestExistenceRequest: GetBucketGuestExistenceRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBucketGuestExistenceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBucketGuestExistence(
        getBucketGuestExistenceRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetBucketGuestsForMyHistoryRequest} getBucketGuestsForMyHistoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBucketGuestsForMyHistory(
      getBucketGuestsForMyHistoryRequest: GetBucketGuestsForMyHistoryRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetBucketGuestsForMyHistoryResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBucketGuestsForMyHistory(
        getBucketGuestsForMyHistoryRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGuestCountForOnboarding(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestCountForOnboardingResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGuestCountForOnboarding(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {GetGuestsForCsvRequest} getGuestsForCsvRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGuestsForCsv(
      getGuestsForCsvRequest: GetGuestsForCsvRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestsForCsvResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGuestsForCsv(
        getGuestsForCsvRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInfoForGuestBucketSelection(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetInfoForGuestBucketSelectionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInfoForGuestBucketSelection(
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {RegisterMyHistoryCompleteRequest} registerMyHistoryCompleteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registerMyHistoryComplete(
      registerMyHistoryCompleteRequest: RegisterMyHistoryCompleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterMyHistoryCompleteResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.registerMyHistoryComplete(
        registerMyHistoryCompleteRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {RegisterOnboardingCompleteRequest} registerOnboardingCompleteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registerOnboardingComplete(
      registerOnboardingCompleteRequest: RegisterOnboardingCompleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterOnboardingCompleteResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.registerOnboardingComplete(
        registerOnboardingCompleteRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * GuestBucketControllerApi - factory interface
 * @export
 */
export const GuestBucketControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GuestBucketControllerApiFp(configuration)
  return {
    /**
     *
     * @param {GetBucketGuestExistenceRequest} getBucketGuestExistenceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBucketGuestExistence(
      getBucketGuestExistenceRequest: GetBucketGuestExistenceRequest,
      options?: any,
    ): AxiosPromise<GetBucketGuestExistenceResponse> {
      return localVarFp
        .getBucketGuestExistence(getBucketGuestExistenceRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetBucketGuestsForMyHistoryRequest} getBucketGuestsForMyHistoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBucketGuestsForMyHistory(
      getBucketGuestsForMyHistoryRequest: GetBucketGuestsForMyHistoryRequest,
      options?: any,
    ): AxiosPromise<GetBucketGuestsForMyHistoryResponse> {
      return localVarFp
        .getBucketGuestsForMyHistory(getBucketGuestsForMyHistoryRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGuestCountForOnboarding(options?: any): AxiosPromise<GetGuestCountForOnboardingResponse> {
      return localVarFp
        .getGuestCountForOnboarding(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetGuestsForCsvRequest} getGuestsForCsvRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGuestsForCsv(
      getGuestsForCsvRequest: GetGuestsForCsvRequest,
      options?: any,
    ): AxiosPromise<GetGuestsForCsvResponse> {
      return localVarFp
        .getGuestsForCsv(getGuestsForCsvRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfoForGuestBucketSelection(
      options?: any,
    ): AxiosPromise<GetInfoForGuestBucketSelectionResponse> {
      return localVarFp
        .getInfoForGuestBucketSelection(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {RegisterMyHistoryCompleteRequest} registerMyHistoryCompleteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerMyHistoryComplete(
      registerMyHistoryCompleteRequest: RegisterMyHistoryCompleteRequest,
      options?: any,
    ): AxiosPromise<RegisterMyHistoryCompleteResponse> {
      return localVarFp
        .registerMyHistoryComplete(registerMyHistoryCompleteRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {RegisterOnboardingCompleteRequest} registerOnboardingCompleteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerOnboardingComplete(
      registerOnboardingCompleteRequest: RegisterOnboardingCompleteRequest,
      options?: any,
    ): AxiosPromise<RegisterOnboardingCompleteResponse> {
      return localVarFp
        .registerOnboardingComplete(registerOnboardingCompleteRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * GuestBucketControllerApi - object-oriented interface
 * @export
 * @class GuestBucketControllerApi
 * @extends {BaseAPI}
 */
export class GuestBucketControllerApi extends BaseAPI {
  /**
   *
   * @param {GetBucketGuestExistenceRequest} getBucketGuestExistenceRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestBucketControllerApi
   */
  public getBucketGuestExistence(
    getBucketGuestExistenceRequest: GetBucketGuestExistenceRequest,
    options?: AxiosRequestConfig,
  ) {
    return GuestBucketControllerApiFp(this.configuration)
      .getBucketGuestExistence(getBucketGuestExistenceRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetBucketGuestsForMyHistoryRequest} getBucketGuestsForMyHistoryRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestBucketControllerApi
   */
  public getBucketGuestsForMyHistory(
    getBucketGuestsForMyHistoryRequest: GetBucketGuestsForMyHistoryRequest,
    options?: AxiosRequestConfig,
  ) {
    return GuestBucketControllerApiFp(this.configuration)
      .getBucketGuestsForMyHistory(getBucketGuestsForMyHistoryRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestBucketControllerApi
   */
  public getGuestCountForOnboarding(options?: AxiosRequestConfig) {
    return GuestBucketControllerApiFp(this.configuration)
      .getGuestCountForOnboarding(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetGuestsForCsvRequest} getGuestsForCsvRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestBucketControllerApi
   */
  public getGuestsForCsv(
    getGuestsForCsvRequest: GetGuestsForCsvRequest,
    options?: AxiosRequestConfig,
  ) {
    return GuestBucketControllerApiFp(this.configuration)
      .getGuestsForCsv(getGuestsForCsvRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestBucketControllerApi
   */
  public getInfoForGuestBucketSelection(options?: AxiosRequestConfig) {
    return GuestBucketControllerApiFp(this.configuration)
      .getInfoForGuestBucketSelection(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {RegisterMyHistoryCompleteRequest} registerMyHistoryCompleteRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestBucketControllerApi
   */
  public registerMyHistoryComplete(
    registerMyHistoryCompleteRequest: RegisterMyHistoryCompleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return GuestBucketControllerApiFp(this.configuration)
      .registerMyHistoryComplete(registerMyHistoryCompleteRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {RegisterOnboardingCompleteRequest} registerOnboardingCompleteRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GuestBucketControllerApi
   */
  public registerOnboardingComplete(
    registerOnboardingCompleteRequest: RegisterOnboardingCompleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return GuestBucketControllerApiFp(this.configuration)
      .registerOnboardingComplete(registerOnboardingCompleteRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
