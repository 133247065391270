/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GetBucketGuestsForMyHistoryResponseGuestsByCommunity } from './get-bucket-guests-for-my-history-response-guests-by-community'

/**
 *
 * @export
 * @interface GetBucketGuestsForMyHistoryResponseGuestsByCategory
 */
export interface GetBucketGuestsForMyHistoryResponseGuestsByCategory {
  /**
   *
   * @type {string}
   * @memberof GetBucketGuestsForMyHistoryResponseGuestsByCategory
   */
  category: GetBucketGuestsForMyHistoryResponseGuestsByCategoryCategory
  /**
   *
   * @type {Array<GetBucketGuestsForMyHistoryResponseGuestsByCommunity>}
   * @memberof GetBucketGuestsForMyHistoryResponseGuestsByCategory
   */
  guestsByCategory: Array<GetBucketGuestsForMyHistoryResponseGuestsByCommunity>
}

/**
 * @export
 * @enum {string}
 */
export enum GetBucketGuestsForMyHistoryResponseGuestsByCategoryCategory {
  family = 'FAMILY',
  school = 'SCHOOL',
  workplace = 'WORKPLACE',
  others = 'OTHERS',
}
