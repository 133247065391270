import { GoogleTagManagerId } from '@/components/common/googleTagManager'
import { Feature } from '@/utils/common/featureToggle'
import { createContext, useContext, useState } from 'react'

export type Config = {
  featureToggle: Feature
  datadogEnv: string | undefined
  clZebraUrl: string
  clUserManual: string
  gtm: GoogleTagManagerId
  clarityId: string
  serviceUrl: string
  coupleLpUrl: string
  csInvitationGuidanceArticleUrl: string
  csGoshugiExperiencesArticleUrl: string
  csGoshugiResearchArticleUrl: string
  csImageShareManualUrl: string
  csInstagramCampaignUrl: string
  csGuestListGuideUrl: string
  guestGoshugiQuestionsUrl: string
  coupleGuestListUpServiceTermUrl: string
  cspNonce: string | undefined
}

type ConfigContextType = {
  config: Config
}

const ConfigContext = createContext<ConfigContextType>({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS2322 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
  config: null,
})

interface Props {
  config: Config
}

export const ConfigContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  config,
  children,
}) => <ConfigContext.Provider value={{ config }}>{children}</ConfigContext.Provider>

export const useConfig = () => useContext(ConfigContext)

export const useConfigFactory = (initialConfig: Config): ConfigContextType => {
  const [config] = useState(initialConfig)
  return {
    config,
  }
}
