import { ErrorHead } from '@/components/common/commonHead'
import { LoggingErrorPageView } from '@/components/common/googleTagManager'
import { RoundMBorderButton } from '@/components/cs/button'
import { ErrorLayout, styles } from '@/components/cs/errorLayout'
import React from 'react'

type OtherErrorProps = {
  title: string
  sub_title: string
  original_screen_name: string
  action_label?: string
  action_ref?: string
}

export default function OtherError({
  title,
  sub_title,
  original_screen_name,
  action_label,
  action_ref,
}: OtherErrorProps) {
  return (
    <ErrorLayout>
      <ErrorHead />
      <LoggingErrorPageView original_screen_name={original_screen_name} />
      <h1 css={[styles.head, styles.breakable_text]}>{title}</h1>
      <p css={[styles.text, styles.breakable_text]}>{sub_title}</p>
      {action_label && action_ref && (
        // ErrorContextなどの状態破棄のためwindow.location.assignを利用
        <RoundMBorderButton
          label={action_label}
          css={styles.button}
          onClick={() => window.location.assign(action_ref)}
        />
      )}
    </ErrorLayout>
  )
}
