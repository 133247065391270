import CheckOnlyAuthentication from '@/components/couple/cs_guest_list_up/auth/CheckOnlyAuthentication'
import NetworkErrorModal from '@/components/cs/networkErrorModal'
import { HeaderMenuComponent } from '@/components/cs_guest_list_up/layout/HeaderMenuComponent'
import { useConfig } from '@/context/config'
import { useError } from '@/context/error'
import { useLogout } from '@/data/hooks/cs_guest_list_up/useLogout'
import { colors } from '@/styles/colors'
import { breakpoints, zIndex } from '@/styles/cs/styleConstants'
import { CS_EXTERNAL_WEB_URL } from '@/utils/common/externalWebURL'
import { lintIgnoredCss } from '@/utils/common/stylelint'
import { css, SerializedStyles } from '@emotion/react'
import { Fade } from '@mui/material'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'

type HeaderType = {
  showMenuButton: boolean
  addHeaderStyle?: SerializedStyles
}

export default function Header({ showMenuButton, addHeaderStyle }: HeaderType) {
  const { config } = useConfig()
  const router = useRouter()

  const [isMenuDisplay, setIsMenuDisplay] = useState(false)
  const [openNetworkErrorModal, setOpenNetworkErrorModal] = useState<boolean>(false)

  const { logout, success, error } = useLogout()
  const { setError } = useError()

  useEffect(() => {
    // DefaultHeader → NoMenuHeader のブラウザバック時にメニュー表示が残ってしまうため、明示的にmenu非表示にする
    setIsMenuDisplay(false)
  }, [showMenuButton])

  useEffect(
    () => () => {
      // メニューを開いたままブラウザバックをするとスクロールができなくなってしまうため、付与されているstyleを削除する
      removeProperty()
    },
    [],
  )

  useEffect(() => {
    if (isMenuDisplay) {
      // TODO: XYSAAS-8902 将来的にoverscroll-behaviorが使えるようになればjsによるbodyFixed処理はcssに代替できる
      document.body.style.position = 'fixed'
      document.body.style.width = '100%'
    } else {
      removeProperty()
    }
  }, [isMenuDisplay])

  useEffect(() => {
    if (success) {
      // ErrorContextなどの状態破棄のためwindow.location.assignを利用
      window.location.assign(success.ridLogoutUrl)
    } else if (error) {
      setError(error.statusCode, error.code)
    }
  }, [success, error])

  const removeProperty = () => {
    document.body.style.removeProperty('position')
    document.body.style.removeProperty('width')
  }

  const partnerInvitationPathName = '/cs_guest_list_up/partner/invitation'
  const topPathName = '/cs_guest_list_up/summary'
  const goToPathName = (pathName: string) => {
    if (router.pathname === pathName) {
      return router.reload()
    }
    // ErrorContextなどの状態破棄のためwindow.location.assignを利用
    return window.location.assign(pathName)
  }

  return (
    <>
      <header css={[styles.header.layout, addHeaderStyle && addHeaderStyle]}>
        <div css={styles.header.logo} />
        {showMenuButton && (
          <CheckOnlyAuthentication>
            <div css={styles.header.navbarButtonArea}>
              <div
                css={styles.header.menu}
                onClick={() => {
                  setIsMenuDisplay(!isMenuDisplay)
                }}
              >
                <button
                  css={[styles.header.menuButton, isMenuDisplay && styles.header.menuButtonActive]}
                >
                  <span />
                  <span />
                  <span />
                </button>
              </div>
            </div>
          </CheckOnlyAuthentication>
        )}
      </header>
      <Fade in={isMenuDisplay} timeout={400}>
        <div css={styles.screen}>
          <div css={styles.overlay} onClick={() => setIsMenuDisplay(false)} />
          <HeaderMenuComponent
            menuItems={[
              {
                itemTitle: 'トップ',
                isNewWindowOpenItem: false,
                onClick: () => {
                  goToPathName(topPathName)
                },
              },
              {
                itemTitle: 'パートナー招待',
                isNewWindowOpenItem: false,
                onClick: () => {
                  goToPathName(partnerInvitationPathName)
                },
              },
              {
                itemTitle: '使い方ガイド',
                isNewWindowOpenItem: true,
                onClick: () => {
                  window.open(CS_EXTERNAL_WEB_URL(config).GUEST_LIST_UP_USER_GUIDE, '_blank')
                },
              },
            ]}
            onLogout={logout}
          />
          <NetworkErrorModal open={openNetworkErrorModal} setOpen={setOpenNetworkErrorModal} />
        </div>
      </Fade>
    </>
  )
}

const styles = {
  header: {
    layout: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 62px;
      padding: 0 40px;

      @media (max-width: ${breakpoints.sp}px) {
        width: 100%;
        height: 65px;
        padding: 0 16px;
        border-bottom: 1px solid rgba(60, 60, 67, 0.1);
      }
    `,
    navbarButtonArea: css`
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
    `,
    helpButton: css`
      display: flex;
      align-items: center;
      padding: 0;
      text-decoration-line: none;
      background: transparent;
      -webkit-tap-highlight-color: transparent;

      &:hover {
        cursor: pointer;
      }
    `,
    logo: css`
      width: 151px;
      height: 36px;
      background: url('/images/header-logo-pc.svg') no-repeat;
      background-size: contain;

      @media (max-width: ${breakpoints.sp}px) {
        width: 66px;
        height: 30px;
        background-image: url('/images/header-logo-sp.svg');
      }
    `,
    menu: css`
      position: relative;
      z-index: ${zIndex.drawer + 1};
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      backdrop-filter: blur(20px);
    `,
    menuButton: css`
      position: relative;
      width: 28px;
      height: 17px;
      cursor: pointer;
      background: none;

      span {
        position: absolute;
        left: 0;
        height: 3px;
        background-color: ${colors.COLOR_EA6077};
        border-radius: 4px;
        transition: all 0.4s;
      }

      span:nth-of-type(1) {
        top: 0;
        width: 100%;
      }

      span:nth-of-type(2) {
        top: 7px;
        width: 100%;
      }

      span:nth-of-type(3) {
        bottom: 0;
        width: 100%;
      }
    `,
    menuButtonActive: css`
      span:nth-of-type(1) {
        transform: translateY(7px) rotate(-45deg);
      }

      span:nth-of-type(2) {
        opacity: 0;
      }

      span:nth-of-type(3) {
        width: 100%;
        transform: translateY(-7px) rotate(45deg);
      }
    `,
  },
  screen: [
    css`
      position: fixed;
      top: 0;
      left: 0;
      z-index: ${zIndex.drawer};
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      overflow-y: auto;
    `,
    lintIgnoredCss` /* 自動ソートの無効化のため */
        /* やりたいことはblur(40px)だが、win10の一部端末などでは縁のblurが弱くなる事象があるため、ぼかし用svgを当てる対応をとる */
        /* see: https://digipress.info/tech/svg-blur-for-css-filter/ */
        backdrop-filter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='flt-a9qhp9hi' x='0' y='0' width='1' height='1' color-interpolation-filters='sRGB'%3E%3CfeGaussianBlur stdDeviation='40' result='flt-a9qhp9hi-2'/%3E%3CfeMorphology operator='dilate' radius='40'/%3E%3CfeMerge%3E%3CfeMergeNode/%3E%3CfeMergeNode in='flt-a9qhp9hi-2'/%3E%3C/feMerge%3E%3C/filter%3E%3C/svg%3E#flt-a9qhp9hi");
        /* safariではsvgによるぼかしが効かないため、そのままblur(40px)を適用する */
        -webkit-backdrop-filter: blur(40px);
      `,
  ],
  overlay: css`
    flex: 1;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
  `,
}
