import { ErrorHead } from '@/components/common/commonHead'
import { RoundMBorderButton } from '@/components/cs/button'
import { ErrorLayout, styles } from '@/components/cs/errorLayout'
import { useRouter } from 'next/router'

export default function NetworkError() {
  const router = useRouter()

  return (
    <ErrorLayout>
      <ErrorHead />
      <h1 css={styles.head}>データの取得に失敗しました。</h1>
      <p css={styles.text}>
        通信環境の良い場所で
        <br />
        再読み込みしてください。
      </p>
      <RoundMBorderButton label="再読み込み" css={styles.button} onClick={() => router.reload()} />
    </ErrorLayout>
  )
}
